/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {sendRequest, baseUrl} from '../../../Helpers/Requests'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import {IndicationLink} from '../../../_metronic/partials/widgets/indicationLink'
import ConfigContext from '../../contexts/Config/ConfigContext'
import { Carousel } from 'react-bootstrap'
const DashboardPage: FC = () => {
  const [assinatura, setAssinatura] = useState(null)
  const [subscription, setSubscription] = useState({})

  const [totalGanhos, setTotalGanhos] = useState<any>(0)
  const [totalBinario, setTotalBinario] = useState<any>(0)
  const [totalDiarios, setTotalDiario] = useState<any>(0)
  const [totalIndicacao, setTotalIndicacao] = useState<any>(0)
  const [totalUnilevel, setTotalUnilevel] = useState<any>(0)
  const [totalResidual, setTotalResidual] = useState<any>(0)

  const [valorGanho, setValorGanho] = useState<any>(0)
  const [valorReceber, setValorReceber] = useState<any>(0)
  const [tetoPlano, setTetoPlano] = useState<any>(0)
  const [prctGanhos, setPrctGanhos] = useState<any>(0)
  const [valorProgresso, setValorProgresso] = useState<any>(0)

  const [saldoTotal, setSaldoTotal] = useState<any>(0)
  const [carreiraPontos, setCarreiraPontos] = useState<any>(0)
  const [carreira, setCarreira] = useState<any>('')
  const [rendimento, setRendimento] = useState<any>(0)
  const [bonificacao, setBonificacao] = useState<any>(0)
  const [binario, setBinario] = useState<any>(false)
  const [ganhoDiario, setGanhoDiario] = useState<any>(null)
  const [dataDiario, setDataDiario] = useState<any>(null)

  const [usuariosEsquerda, setUsuariosEsquerda] = useState<any>(0)
  const [usuariosDireita, setUsuariosDireita] = useState<any>(0)
  const [pontosEsquerda, setPontosEsquerda] = useState<any>(0)
  const [pontosDireita, setPontosDireita] = useState<any>(0)
  const [totalUsuarios, setTotalUsuarios] = useState<any>(0)
  const [loadedCards, setLoadedCards] = useState<boolean>(false)
  const config = useContext(ConfigContext)

  const getAssinatura = async () => {
    const req = sendRequest(`usuario/assinatura`, 'GET')

    const response = await req

    if (response.tipo === 'success') {
      const {
        plan,
        valor_progresso,
        val_receber,
        val_ganho,
        teto_plano,
        prct_ganho,
        binarioQualificado,
      } = response

      setBinario(binarioQualificado)
      setSubscription(response)
      setAssinatura(plan)
      setValorGanho(val_ganho)
      setValorReceber(val_receber)
      setTetoPlano(teto_plano)
      setPrctGanhos(prct_ganho)
      setValorProgresso(valor_progresso)
    }
  }

  const getGanhos = async () => {
    const req = await sendRequest(`usuario/ganhos`, 'GET')

    const response = await req

    if (response.tipo === 'success') {
      const {
        binario_total,
        diario_total,
        entradas_total,
        indicacao_total,
        residual_total,
        unilevel_total,
      } = response

      setTotalGanhos(entradas_total)
      setTotalBinario(binario_total)
      setTotalDiario(diario_total)
      setTotalIndicacao(indicacao_total)
      setTotalUnilevel(unilevel_total)
      setTotalResidual(residual_total)
    }
  }

  const getSaldo = async () => {
    const req = await sendRequest('usuario/saldo', 'GET')
    const response = await req

    if (response.tipo === 'success') {
      const {
        daily,
        data_verificacao,
        pontos_direita,
        pontos_esquerda,
        saldo,
        saldo_bonificacoes,
        saldo_rendimento,
        pontos_carreira,
        carreira,
      } = response

      setSaldoTotal(saldo)
      setRendimento(saldo_rendimento)
      setBonificacao(saldo_bonificacoes)
      setGanhoDiario(daily)
      setDataDiario(data_verificacao)
      setPontosDireita(pontos_direita)
      setPontosEsquerda(pontos_esquerda)
      setCarreiraPontos(pontos_carreira)
      setCarreira(carreira ?? 'N/A')
    }
  }

  const getTotalUsuarios = async () => {
    const req = await sendRequest('rede/total_usuarios', 'GET')
    const response = await req

    if (response.tipo === 'success') {
      setUsuariosDireita(response.formato.direita)
      setUsuariosEsquerda(response.formato.esquerda)
      setTotalUsuarios(response.formato.direita + response.formato.esquerda)
    }
  }

  const getAllData = async () => {
    await getTotalUsuarios()
    await getSaldo()
    await getGanhos()
    await getAssinatura()
    setLoadedCards(true)
  }

  useEffect(() => {
    getAllData()
  }, [])

  return (
    <>
      {config.banners.length > 0 &&
          <div className='row g-5 mb-1 mb-xl-1'>
            <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12  '>
              <Carousel touch={true}>
                {config.banners.map((item: { to: any, path: any }) => (
                  <Carousel.Item>
                    <a target="_blank" href={item.to}>
                      <img className='d-block w-100' src={baseUrl(`storage/${item.path}`)} />
                    </a>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        }
      <div className='row g-5 mb-1 mb-xl-1'>
        <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12  '>
          <IndicationLink className='card-xxl-stretch mb-5 mb-xxl-12' config={config} />
        </div>
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-1 mb-xl-1'>
        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className={config.binario ? 'h-md-50 mb-5 mb-xl-10' : 'h-md-100 mb-5 mb-xl-10'}
            description='Retorno do investimento'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            valorGanho={valorGanho}
            valorReceber={valorReceber}
            tetoPlano={tetoPlano}
            prctGanhos={prctGanhos}
            progresso={valorProgresso}
            config={config}
            assinatura={assinatura}
            subscription={subscription}
            loaded={loadedCards}
          />
          {config.binario && (
            <CardsWidget7
              className='h-md-50 mb-5 mb-xl-10'
              description='Usuários na minha rede'
              icon={false}
              stats={totalUsuarios}
              labelColor='dark'
              textColor='gray-300'
              binario={binario}
              direita={usuariosDireita}
              esquerda={usuariosEsquerda}
              pontosDireita={pontosDireita}
              pontosEsquerda={pontosEsquerda}
              config={config}
              carreira={carreira}
              pontosCarreira={carreiraPontos}
              loaded={loadedCards}
            />
          )}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          {/* Card Ganhos */}
          <CardsWidget17
            className={config.binario ? 'h-md-50 mb-5 mb-xl-10' : 'h-md-100 mb-5 mb-xl-10'}
            total={totalGanhos}
            binario={totalBinario}
            diario={totalDiarios}
            indicacao={totalIndicacao}
            unilevel={totalUnilevel}
            residual={totalResidual}
            config={config}
            loaded={loadedCards}
          />

          {config.binario && (
            <ListsWidget26
              className='h-md-50 mb-5 mb-xl-10'
              binario={binario}
              saldoTotal={saldoTotal}
              rendimento={rendimento}
              bonificacao={bonificacao}
              ganhoDiario={ganhoDiario}
              dataDiario={dataDiario}
              config={config}
              carreira={carreira}
              pontosCarreira={carreiraPontos}
              loaded={loadedCards}
            />
          )}
        </div>
        {!config.binario && (
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-6 mb-md-5 mb-xl-10'>
            {/* Card Ganhos */}
            <ListsWidget26
              className='h-lg-100'
              binario={binario}
              saldoTotal={saldoTotal}
              rendimento={rendimento}
              bonificacao={bonificacao}
              ganhoDiario={ganhoDiario}
              dataDiario={dataDiario}
              config={config}
              pontosCarreira={carreiraPontos}
              carreira={carreira}
              loaded={loadedCards}
            />
          </div>
        )}
        {/* end::Col */}

        {/* begin::Col */}
        <div className={config.binario ? 'col-xxl-6' : 'col-xxl-12'}>
          <EngageWidget10
            className='h-md-100'
            plan={assinatura}
            config={config}
            loaded={loadedCards}
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
